<template>
    <div>
      <!--begin::Content header-->
      <!--end::Content header-->
  
      <!--begin::Signup-->
      <div class="row mt-5">
        <!-- <div class="col-md-3"></div> -->
        <div class="col-md-5 offset-md-4">
          <div class="card card-custom gutter-b">
            <div class="card-header">
              <div class="card-title">
                <h3 class="card-label">Reset Password</h3>
              </div>
            </div>
            <div class="card-body body-fluid">
              <div class="row" v-if="this.form.email && this.show">
                <div class="col-md-12">
                  <label>Password</label>
                  <b-form-input
                    id="password"
                    type="password"
                    placeholder="Password"
                    v-model="form.password"
                    v-on:keyup.enter="resetPassword"
                  ></b-form-input>
                </div>
                <div class="col-md-12 mt-2">
                  <label>Confirm Password</label>
                  <b-form-input
                    id="password_confirmation"
                    type="password"
                    placeholder="Password"
                    v-model="form.password_confirmation"
                    v-on:keyup.enter="resetPassword"
                  ></b-form-input>
                </div>
              </div>
              <div class="row" v-else-if="this.show && !this.form.email">
                <p>Invalid Token</p>
              </div>
            </div>
            <div class="card-footer">
              <b-button variant="primary" @click="resetPassword">
                Submit
              </b-button>
            </div>
          </div>
        </div>
      </div>
  
      <!--end::Signup-->
      <loading :active.sync="isLoading"></loading>
    </div>
  </template>
  
  <style lang="scss" scoped>
  .spinner.spinner-right {
    padding-right: 3.5rem !important;
  }
  </style>
  
  <script>
  import ApiService from "@/core/services/api.service";
  import Swal from "sweetalert2";
  import Loading from "vue-loading-overlay";
  import "vue-loading-overlay/dist/vue-loading.css";
  export default {
    name: "resetpassword",
    data() {
      return {
        form: {
          password: "",
          password_confirmation: "",
          email: "",
          token: ""
        },
        isLoading: false,
        show: false,
      };
    },
    components: {
      Loading,
    },
    created() {
        this.isLoading = true;
        this.checkResetToken();
    },
    methods: {
      resetPassword() {
        if(this.form.password != this.form.password_confirmation){
            Swal.fire({
              title: "Error",
              text: "Both passwords must be same",
              icon: "error",
              confirmButtonClass: "btn btn-primary",
              heightAuto: false,
            });
            return;
        }
        this.isLoading = true;
        ApiService.post("reset", this.form)
          .then(() => {
            this.isLoading = false;
            Swal.fire({
              title: "",
              text: "Password Changed Successfully",
              icon: "success",
              confirmButtonClass: "btn btn-primary",
              heightAuto: false,
            });
            this.$router.push({ name: "login" });
          })
          .catch(({ response }) => {
            
            this.isLoading = false;
            for (let field of Object.keys(response.data.error)) {
              this.makeToastVariant("danger", response.data.error[field][0]);
            }
          });
      },
      makeToastVariant(variant = null, text) {
        this.$bvToast.toast(text, {
          title: `Error`,
          variant: variant,
          solid: true,
        });
      },
      checkResetToken() {
        ApiService.get("find", this.$route.params.token)
          .then(({ data }) => {
            this.form.email = data.email;
            this.form.token = data.token;
            this.isLoading = false;
            this.show = true;
          })
          .catch(() => {
            this.isLoading = false;
            this.show = true;
          });
      },
    }
  };
  </script>
  